const URL = process.env.REACT_APP_NPS_APIS_URL;

export const unsubscribeEmailAddress = async unsubscribeToken => {
  const res = await fetch(`${URL}/unsubscribe`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${unsubscribeToken}`,
    },
  });

  return res;
};

export const postNpsScore = async npsData => {
  const res = await fetch(`${URL}/npsScore`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(npsData),
  });

  return res;
};

export const postAuthToken = async authToken => {
  const res = await fetch(`${URL}/validateAuthToken`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(authToken),
  });

  return res;
};

export const postUnsubscribeToken = async unsubscribeToken => {
  const res = await fetch(`${URL}/validateUnsubscribeToken`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${unsubscribeToken}`,
    },
  });

  return res;
};

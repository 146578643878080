import { Route, Routes } from "react-router-dom";

import { Expiry } from "../pages/Expiry/Expiry";
import { Submit } from "../pages/Submit/Submit";
import { SubmitAlready } from "../pages/SubmitAlready/SubmitAlready";
import { SubmitSuccess } from "../pages/SubmitSuccess/SubmitSuccess";
import { Unsubscribe } from "../pages/Unsubscribe/Unsubscribe";
import { UnsubscribeSuccess } from "../pages/UnsubscribeSuccess/UnsubscribeSuccess";
import * as routes from "./constants";

export const Router = () => {
  return (
    <Routes>
      <Route path={routes.SUBMIT} element={<Submit />} />
      <Route path={routes.SUBMIT_SUCCESS} element={<SubmitSuccess />} />
      <Route path={routes.SUBMIT_ALREADY} element={<SubmitAlready />} />
      <Route path={routes.EXPIRY} element={<Expiry />} />
      <Route path={routes.UNSUBSCRIBE} element={<Unsubscribe />} />
      <Route
        path={routes.UNSUBSCRIBE_SUCCESS}
        element={<UnsubscribeSuccess />}
      />
      <Route path="*" element={<Expiry />} />
    </Routes>
  );
};

import PropTypes from "prop-types";

import styles from "./NpsText.module.scss";

export const NpsText = ({ children }) => {
  return <div className={styles.text}>{children}</div>;
};

NpsText.propTypes = {
  children: PropTypes.node,
};

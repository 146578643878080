import { Trackable } from "@dpdgroupuk/react-event-tracker";

import ratingImg from "../../assets/images/ratingsImage.jpg";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { NpsImg } from "../../components/NpsImg/NpsImg";
import { FEEDBACK_SUBMIT_SUCCESS } from "../../constants/analytics";
import { closeBrowserWindow } from "../../helpers/browser";

export const SubmitSuccess = () => {
  return (
    <Trackable
      interfaceId={FEEDBACK_SUBMIT_SUCCESS.INTERFACE_ID}
      loadId={FEEDBACK_SUBMIT_SUCCESS.ON_LOAD}
    >
      <Header text="Thank you for your feedback" />
      <NpsImg img={ratingImg} alt={"Group of people holding stars"} />
      <Button
        onClick={closeBrowserWindow}
        actionId={FEEDBACK_SUBMIT_SUCCESS.ON_CLICK_CLOSE}
      >
        Close
      </Button>
      <Footer />
    </Trackable>
  );
};

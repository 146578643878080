import { useEffect, useState } from "react";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const [dpdBrand, setDpdBrand] = useState("");

  useEffect(() => {
    if (window.location.href.includes("dpdlocal")) {
      setDpdBrand("dpdlocal");
    } else {
      setDpdBrand("dpd");
    }
  }, []);

  return (
    <div className={styles.container}>
      <a
        href={`https://www.${dpdBrand}.co.uk/ts-and-cs.jsp`}
        target="_blank"
        rel="noreferrer"
      >
        Terms &amp; Conditions
      </a>
      <span>|</span>
      <a
        href={`https://www.${dpdBrand}.co.uk/privacy_policy.jsp`}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Notice
      </a>
      <span>|</span>
      <a
        href={`https://www.${dpdBrand}.co.uk/content/about_dpd/phishing.jsp`}
        target="_blank"
        rel="noreferrer"
      >
        Phishing
      </a>
    </div>
  );
};

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { UNSUBSCRIBE_SUCCESSFUL } from "../../constants/analytics";
import { closeBrowserWindow } from "../../helpers/browser";

export const UnsubscribeSuccess = () => {
  return (
    <Trackable
      interfaceId={UNSUBSCRIBE_SUCCESSFUL.INTERFACE_ID}
      loadId={UNSUBSCRIBE_SUCCESSFUL.ON_LOAD}
    >
      <Header text="Unsubscribe successful." />
      <Button
        onClick={closeBrowserWindow}
        actionId={UNSUBSCRIBE_SUCCESSFUL.ON_CLICK_CLOSE}
      >
        Close
      </Button>
      <Footer />
    </Trackable>
  );
};

import PropTypes from "prop-types";

import { NPS_SCORES } from "../../constants/constants";
import { NpsScoreInput } from "../NpsScoreInput/NpsScoreInput";
import styles from "./NpsScoreInputs.module.scss";

export const NpsScoreInputs = ({ selectedNpsScore, setSelectedNpsScore }) => {
  return (
    <>
      <div className={styles.container}>
        {NPS_SCORES.map(npsScore => (
          <NpsScoreInput
            key={npsScore}
            score={npsScore}
            selectedNpsScore={selectedNpsScore}
            setSelectedNpsScore={setSelectedNpsScore}
          />
        ))}
      </div>
      <div className={styles.text}>
        <p>Not at all likely</p>
        <p>Extremely likely</p>
      </div>
    </>
  );
};

NpsScoreInputs.propTypes = {
  selectedNpsScore: PropTypes.string,
  setSelectedNpsScore: PropTypes.func,
};

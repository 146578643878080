import PropTypes from "prop-types";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { FEEDBACK_REQUIRED } from "../../constants/analytics";
import { NPS_COLORS } from "../../constants/constants";
import styles from "./NpsScoreInput.module.scss";

export const NpsScoreInput = ({
  score,
  selectedNpsScore,
  setSelectedNpsScore,
}) => {
  const tracker = useTracker();
  const isSelected = score === selectedNpsScore;

  const handleOnClick = () => {
    tracker.logEvent(FEEDBACK_REQUIRED.ON_SELECT_SCORE);
    setSelectedNpsScore(score);
  };

  return (
    <div
      className={styles["score-input"]}
      onClick={handleOnClick}
      style={{
        backgroundColor: isSelected ? `#${NPS_COLORS[score]}` : "#fff",
        color: isSelected && "#fff",
        borderColor: isSelected && `#${NPS_COLORS[score]}`,
      }}
    >
      {score}
    </div>
  );
};

NpsScoreInput.propTypes = {
  score: PropTypes.string,
  selectedNpsScore: PropTypes.string,
  setSelectedNpsScore: PropTypes.func,
};

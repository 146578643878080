import PropTypes from "prop-types";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import styles from "./Button.module.scss";

export const Button = ({ children, onClick, actionId }) => {
  const tracker = useTracker();

  const handleOnClick = () => {
    tracker.logEvent(actionId);
    onClick();
  };

  return (
    <button onClick={handleOnClick} type="button" className={styles.btn}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  actionId: PropTypes.string,
};

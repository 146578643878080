export const NPS_SCORES = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const NPS_COLORS = {
  0: "CC271C",
  1: "E22D38",
  2: "EB4D3D",
  3: "EE7D3B",
  4: "F2A33A",
  5: "F5C443",
  6: "E2CC43",
  7: "BAD443",
  8: "8CD942",
  9: "63D940",
  10: "30D100",
};

import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import styles from "./Header.module.scss";

const imagePath = process.env.REACT_APP_IMAGES_PATH;

export const Header = ({ text = "Would you recommend DPD?" }) => {
  const [dpdImgSrc, setDpdImgSrc] = useState("");

  useEffect(() => {
    if (window.location.href.includes("dpdlocal")) {
      setDpdImgSrc(`${imagePath}/dpdLocalLogo.png`);
    } else {
      setDpdImgSrc(`${imagePath}/dpdLogo.png`);
    }
  }, []);

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={dpdImgSrc} />
      <h1 className={styles.text}>{text}</h1>
    </div>
  );
};

Header.propTypes = {
  text: PropTypes.string,
};

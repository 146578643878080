import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { postUnsubscribeToken, unsubscribeEmailAddress } from "../../apis/apis";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { NpsText } from "../../components/NpsText/NpsText";
import { UNSUBSCRIBE_FROM_EMAILS } from "../../constants/analytics";
import { EXPIRY, UNSUBSCRIBE_SUCCESS } from "../../router/constants";

export const Unsubscribe = () => {
  const [isUnsubscribeTokenValid, setIsUnsubscribeTokenValid] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const unsubscribeToken = searchParams.get("unsubscribeToken");

  useEffect(() => {
    const validateUnsubscribeToken = async () => {
      try {
        const res = await postUnsubscribeToken(unsubscribeToken);

        // If user has already unsubscribed, show unsubscribed success page
        if (!res.ok && res.status === 409) {
          navigate(UNSUBSCRIBE_SUCCESS);
        } else if (!res.ok) {
          navigate(EXPIRY);
        } else {
          setIsUnsubscribeTokenValid(true);
        }
      } catch (error) {
        navigate(EXPIRY);
      }
    };

    validateUnsubscribeToken();
  }, []);

  const handleOnClick = async () => {
    const token = searchParams.get("unsubscribeToken");

    try {
      const res = await unsubscribeEmailAddress(token);

      if (!res.ok) {
        navigate(EXPIRY);
      } else {
        navigate(UNSUBSCRIBE_SUCCESS);
      }
    } catch (error) {
      navigate(EXPIRY);
    }
  };

  return (
    <Trackable
      interfaceId={UNSUBSCRIBE_FROM_EMAILS.INTERFACE_ID}
      loadId={UNSUBSCRIBE_FROM_EMAILS.ON_LOAD}
    >
      {isUnsubscribeTokenValid && (
        <>
          <Header text="Sorry to see you go!" />
          <NpsText>
            <p>
              Please click confirm if you wish to unsubscribe from this survey
            </p>
          </NpsText>
          <Button
            onClick={handleOnClick}
            actionId={UNSUBSCRIBE_FROM_EMAILS.ON_CLICK_CONFIRM}
          >
            Confirm
          </Button>
          <Footer />
        </>
      )}
    </Trackable>
  );
};

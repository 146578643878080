import { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";

import { Container } from "./components/Container/Container";
import { Router } from "./router/Router";
import tracker from "./utils/analytics";

export const App = () => {
  useEffect(() => {
    const favicon = document.getElementById("favicon");

    if (window.location.href.includes("dpdlocal")) {
      favicon.href = `${process.env.PUBLIC_URL}/dpdlocal.ico`;
    } else {
      favicon.href = `${process.env.PUBLIC_URL}/dpd.ico`;
    }
  }, []);

  return (
    <TrackerProvider tracker={tracker}>
      <BrowserRouter>
        <Container>
          <Router />
        </Container>
      </BrowserRouter>
    </TrackerProvider>
  );
};

import PropTypes from "prop-types";

import styles from "./NpsImg.module.scss";

export const NpsImg = ({ img, alt = "Nps img" }) => {
  return (
    <div className={styles["img-container"]}>
      <img className={styles.img} src={img} alt={alt} />
    </div>
  );
};

NpsImg.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
};

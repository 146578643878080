export const FEEDBACK_REQUIRED = {
  INTERFACE_ID: "RN7CU0GFYFxiHzjE",
  ON_LOAD: "FSq000GFYFxiH3sA",
  ON_SELECT_SCORE: "znU000GFYFxiH3qo",
  ON_CLICK_SUBMIT: "4.W000GFYFxiH3sG",
};

export const FEEDBACK_PROVIDED = {
  INTERFACE_ID: "BSQiU0GFYFxiHzvH",
  ON_LOAD: "sRN6U0GFYFxiH3bx",
  ON_CLICK_CLOSE: "9KRY00GFYFxiH8F0",
};

export const FEEDBACK_SUBMIT_SUCCESS = {
  INTERFACE_ID: "tsYiU0GFYFxiHzvP",
  ON_LOAD: "eImi80GFYFxiH0Zc",
  ON_CLICK_CLOSE: "8Ymi80GFYFxiH0Zw",
};

export const EXPIRED_SESSION = {
  INTERFACE_ID: "HSSiU0GFYFxiHzvx",
  ON_LOAD: "cNh280GFYFxiH9xo",
  ON_CLICK_CLOSE: "Sdh280GFYFxiH9x9",
};

export const UNSUBSCRIBE_FROM_EMAILS = {
  INTERFACE_ID: "8cEiU0GFYFxiHzvU",
  ON_LOAD: "_k6e80GFYFxiHw6P",
  ON_CLICK_CONFIRM: "p06e80GFYFxiHw6h",
};

export const UNSUBSCRIBE_SUCCESSFUL = {
  INTERFACE_ID: "kWsiU0GFYFxiHzvi",
  ON_LOAD: "_Xke80GFYFxiHw5M",
  ON_CLICK_CLOSE: "dvke80GFYFxiHw5e",
};

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { NpsText } from "../../components/NpsText/NpsText";
import { EXPIRED_SESSION } from "../../constants/analytics";
import { closeBrowserWindow } from "../../helpers/browser";

export const Expiry = () => {
  return (
    <Trackable
      interfaceId={EXPIRED_SESSION.INTERFACE_ID}
      loadId={EXPIRED_SESSION.ON_LOAD}
    >
      <Header text="Sorry this page has expired. " />
      <NpsText>
        <p>Please try again</p>
      </NpsText>
      <Button
        onClick={closeBrowserWindow}
        actionId={EXPIRED_SESSION.ON_CLICK_CLOSE}
      >
        Close
      </Button>
      <Footer />
    </Trackable>
  );
};

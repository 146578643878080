import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { postAuthToken, postNpsScore } from "../../apis/apis";
import ratingImg from "../../assets/images/ratingsImage.jpg";
import { Button } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { NpsImg } from "../../components/NpsImg/NpsImg";
import { NpsScoreInputs } from "../../components/NpsScoreInputs/NpsScoreInputs";
import { NpsText } from "../../components/NpsText/NpsText";
import { FEEDBACK_REQUIRED } from "../../constants/analytics";
import { NPS_SCORES } from "../../constants/constants";
import { EXPIRY, SUBMIT_ALREADY, SUBMIT_SUCCESS } from "../../router/constants";

export const Submit = () => {
  const [selectedNpsScore, setSelectedNpsScore] = useState("");
  const [isAuthTokenValid, setIsAuthTokenValid] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [dpdBrand, setDpdBrand] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authToken = searchParams.get("authToken");
  const npsScore = searchParams.get("npsScore");

  useEffect(() => {
    if (window.location.href.includes("dpdlocal")) {
      setDpdBrand("DPD Local");
    } else {
      setDpdBrand("DPD");
    }

    if (npsScore) {
      setSelectedNpsScore(npsScore);
    }

    const validateAuthToken = async () => {
      try {
        const res = await postAuthToken({ authToken });

        if (!res.ok && res.status === 409) {
          navigate(SUBMIT_ALREADY);
        } else if (!res.ok) {
          navigate(EXPIRY);
        } else {
          setIsAuthTokenValid(true);
        }
      } catch (error) {
        navigate(EXPIRY);
      }
    };

    validateAuthToken();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
  }, [showErrorMessage]);

  const handleOnClickSubmit = async () => {
    if (selectedNpsScore === "" || !NPS_SCORES.includes(selectedNpsScore)) {
      setShowErrorMessage(true);
      return;
    }

    try {
      const res = await postNpsScore({
        npsScore: +selectedNpsScore,
        authToken,
      });

      if (!res.ok && res.status === 409) {
        navigate(SUBMIT_ALREADY);
      } else if (!res.ok) {
        navigate(EXPIRY);
      } else {
        navigate(SUBMIT_SUCCESS);
      }
    } catch (error) {
      navigate(EXPIRY);
    }
  };

  return (
    <Trackable
      interfaceId={FEEDBACK_REQUIRED.INTERFACE_ID}
      loadId={FEEDBACK_REQUIRED.ON_LOAD}
    >
      {isAuthTokenValid && (
        <>
          <Header text={`Would you recommend ${dpdBrand}`} />
          <NpsText>
            <p>On a scale of 0 - 10</p>
            <p>{`How likely are you to recommend ${dpdBrand} to a friend or colleague?`}</p>
          </NpsText>
          <NpsScoreInputs
            selectedNpsScore={selectedNpsScore}
            setSelectedNpsScore={setSelectedNpsScore}
          />
          {showErrorMessage && (
            <NpsText>
              <p style={{ color: "red" }}>Please select a nps score</p>
            </NpsText>
          )}
          <NpsImg img={ratingImg} alt={"Group of people holding stars"} />
          <Button
            onClick={handleOnClickSubmit}
            actionId={FEEDBACK_REQUIRED.ON_CLICK_SUBMIT}
          >
            Submit
          </Button>
          <Footer />
        </>
      )}
    </Trackable>
  );
};
